import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Bio from "../components/bio"
import Layout from "../components/layout"
import Nav from "../components/nav"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import "../styles/blog-post.css";
import "katex/dist/katex.min.css"
import PostsDisplay from "../components/posts-display"

const BlogPostTemplate = ({ data, pageContext, location, suggestedReading }) => {
  const post = data.mdx
  const { previous, next } = pageContext
  //only save posts which are suggested reading.
  var posts;
  if (post.frontmatter.suggestedReading != null) {
    posts = data.allMdx.edges
      .filter(({ node }) => {
        return post.frontmatter.suggestedReading.includes(node.fields.slug.slice(1, -1))
      })
  }
  //save featured image for social sharing

  const image = post.frontmatter.image
    ? post.frontmatter.image.childImageSharp.resize
    : null
  return (
    <Layout location={location}
    >
      
    <Nav
    image
    />
      <SEO
        title={post.frontmatter.title}
        image={image}
        pathname={location.pathname}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>


          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >{post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date}
          </p>
          {post.frontmatter.suggestedReading &&
            <div className={"suggested-reading"}>

              <p className={"suggested-reading-header"}>
                Hey! This post references terminology and material covered in previous blog posts. If any concept below is new to you, I <strong>strongly</strong> suggest you check out its corresponding post{posts.length > 1 ? 's' : ''}.
          </p> 
              <PostsDisplay posts={posts} showImage={false} />
            </div>
          }
        </header>

        <MDXRenderer>{post.body}</MDXRenderer>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image: featured {
              childImageSharp {
                fixed(width: 630) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        suggestedReading
        image: featured {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`